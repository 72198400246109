import axios from '@/plugins/axios';

export const fetchStyleList = async (): Promise<any> => new Promise((resolve, reject) => {
  const url = '/styles?limit=30&offset=0&orderBy[createdAt]=desc';
  axios.get(url).then((result) => {
    resolve(result.data.data);
  }).catch((e) => {
    reject(e.response.data);
  });
});
