<template>
  <div class="ai-drop-loader">
    <div class="ai-drop-loader__bubble">
      <div class="ai-drop-loader__circle ai-drop-loader__circle--1"></div>
      <div class="ai-drop-loader__circle ai-drop-loader__circle--2"></div>
      <div class="ai-drop-loader__circle ai-drop-loader__circle--3"></div>
      <div class="ai-drop-loader__circle ai-drop-loader__circle--4"></div>
    </div>
  </div>
</template>

<style scoped lang="scss" src="./AiDropLoader.scss"/>
