// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Source_Sans_Pro/SourceSansPro-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/SFProDisplay/SFProDisplay-Semibold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("../fonts/SFProDisplay/SFProDisplay-Semibold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("../fonts/SFProDisplay/SFProDisplay-Semibold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("../fonts/SFProDisplay/SF-Arabic-font.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___, { hash: "?#iefix" });
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Source Sans Pro;font-weight:300;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"ttf\")}@font-face{font-family:Source Sans Pro;font-weight:400;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"ttf\")}@font-face{font-family:Source Sans Pro;font-weight:600;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"ttf\")}@font-face{font-family:Source Sans Pro;font-weight:600;font-display:swap;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"ttf\")}@font-face{font-family:SFProDisplay;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ");src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"embedded-opentype\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"truetype\");font-weight:550;font-style:normal}@font-face{font-family:SFProDisplayAr;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\");font-weight:550;font-style:normal}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
