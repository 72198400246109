import { TTab } from '@/types/TTab';
import { State } from './types';

export const state: State = {
  aiCanvasRef: null,
  colors: [],
  generateImageData: {
    activeTab: 'theme' as TTab,
    color: '',
    canvas: {
      width: 2048,
      height: 2048,
    },
    activeSizeIndex: 0,
    negativeList: [],
    prompt: '',
    style: null,
    theme: null,
    imagesQn: 1,
    products: [],
    loading: false,
  },
  loading: false,
  stylesList: [],
  themesList: [],
  selectedProduct: undefined,
};
