const downloadImage = ({ url, name }: { url: string; name: string }): void => {
  fetch(url)
    .then((responseObject: any) => responseObject.blob())
    .then((blobObject: any) => saveImg(blobObject, name));
};

const saveImg = (blob: Blob, name: string): void => {
  const link = document.createElement('a');
  link.setAttribute('href', URL.createObjectURL(blob));
  link.setAttribute('download', name);
  link.click();
};

export { downloadImage, saveImg };
