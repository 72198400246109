import axios, { AxiosInstance } from 'axios';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/store/auth';

import i18n from '../i18n';

const headers = {
  'Content-type': 'application/json',
};

const options = {
  baseURL: process.env.VUE_APP_API_URL,
  headers,
};

const apiClient: AxiosInstance = axios.create(options);

apiClient.interceptors.request.use((config) => {
  const { token } = storeToRefs(useAuthStore());
  // const token = localStorage.getItem('token');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token.value}`;
  // if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  config.headers.lang = i18n.global.locale.value;
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error),
);

export default apiClient;
