export const removeBase64Prefix = (base64Data: any): string => {
  const base64Prefixes = ['data:image/jpeg;base64,', 'data:image/png;base64,', 'data:image/gif;base64,', 'data:image/webp;base64,'];
  let cleanedData = base64Data;
  base64Prefixes.forEach((prefix) => {
    if (base64Data.startsWith(prefix)) {
      cleanedData = base64Data.slice(prefix.length);
    }
  });
  return cleanedData;
};
