import messagesRu from '@/assets/i18n/ru.json';
import messagesEn from '@/assets/i18n/en.json';
import messagesAr from '@/assets/i18n/ar.json';
import messagesEs from '@/assets/i18n/es.json';
import messagesKr from '@/assets/i18n/kr.json';
import { createI18n } from 'vue-i18n';

const messagesLang = {
  ...messagesRu,
  ...messagesEn,
  ...messagesAr,
  ...messagesEs,
  ...messagesKr,
};

const i18n = createI18n({
  legacy: false,
  locale: 'ru',
  fallbackLocale: 'en',
  messages: messagesLang,
});

export default i18n;
