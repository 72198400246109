export const utils = [
  'crop',
  'filter',
  'finetune',
  // 'annotate',
  'decorate',
  'sticker', // add this line
  'frame',
  // 'redact',
  // 'resize',
];
