import i18n from '@/plugins/i18n';
import { TLang } from '@/types/TLang';

export const setLocale = (lang: TLang): void => {
  i18n.global.locale.value = lang;
  localStorage.setItem('lang', lang);
  const app = document.querySelector((window as any)?.AI_WIDGET_SETTINGS.el);
  if (app?.classList.contains('app-arab')) {
    if (lang !== 'ar') {
      app.classList.remove('app-arab');
    }
  } else if (lang === 'ar') {
    app?.classList.add('app-arab');
  }
};
